@use 'color/color';

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import 'node_modules/@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import 'node_modules/@ionic/angular/css/normalize.css';
@import 'node_modules/@ionic/angular/css/structure.css';
@import 'node_modules/@ionic/angular/css/typography.css';
@import 'node_modules/@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import 'node_modules/@ionic/angular/css/padding.css';
@import 'node_modules/@ionic/angular/css/float-elements.css';
@import 'node_modules/@ionic/angular/css/text-alignment.css';
@import 'node_modules/@ionic/angular/css/text-transformation.css';
@import 'node_modules/@ionic/angular/css/flex-utils.css';

@import 'ui';

// Load branding module which sets the primary color CSS variables
@import '../../../libs/feature/branding/src/lib/brands.scss';

html,
body {
  background: #ffffff;
}

.mat-mdc-snack-bar-handset {
  transform: translateY(
    calc(env(safe-area-inset-bottom) * -1 - 3.5rem)
  ) !important; /* stylelint-disable-line declaration-no-important */
}

.footer-md::before {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.mat-chip-list-wrapper {
  flex-wrap: nowrap !important; /* stylelint-disable-line declaration-no-important */
}

.keyboard-shown .hide-element-on-keyboard-shown {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

// Position FreshWorks widget to account for safe-area-inset
@media only screen and (max-device-width: 667px), screen and (max-width: 450px) {
  #freshworks-container #freshworks-frame-wrapper {
    top: env(safe-area-inset-top) !important; /* stylelint-disable-line declaration-no-important */
    border-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */

    &::after {
      content: '';
      left: 0;
      width: 100%;
      height: env(safe-area-inset-top);
      top: 0;
      background: color.$primary-600;
      position: fixed;
    }

    &::before {
      content: '';
      left: 0;
      width: 100%;
      height: env(safe-area-inset-bottom);
      bottom: 0;
      background: color.$pebble-0;
      position: fixed;
    }

    #widget-frame {
      height: calc(
        100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)
      ) !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

// Force hide the launcher pill button on mobile
#freshworks-container #launcher-frame {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}
